import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = [
  {
    title: "LineChart Samples",
    postId: "abd4b0c8cd6c",
  },
  {
    title: "BarChart Samples",
    postId: "b508a82cc056",
  },

  {
    title: "Cytoscape Samples",
    postId: "c6cd3d82e288",
  },

  {
    title: "ReactFlow Samples",
    postId: "cd454557ebb1",
  },
  {
    title: "ReactFlow Samples",
    postId: "cd454557ebb1",
  },
  {
    title: "VisJS Samples",
    postId: "3121a8ccf6e4",
  },
  {
    title: "TraceChart Samples",
    postId: "f7351bac7221",
  },

  {
    title: "FlameGraph Samples",
    postId: "eb70f41a2803",
  },

  {
    title: "Heatmap Samples",
    postId: "69ebf3f3c187",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Visualization Samples",
  path: "react-visualization-samples",
}

const ReactVisualizationSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactVisualizationSamples
